//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//获取排行版列表
const getCommentPraiseRankingListURL =
  "/gateway/hc-neighbour/topic/getCommentPraiseRankingList";
//获取积分商城详情
const getTopicDetailURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
// //话题类型
// const getInitInfoURL = "/gateway/hc-neighbour/talent/getInitInfo";
//话题姓名
const getTalentUserListURL = `/gateway/hc-neighbour/talent/getTalentUserList`;
//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
// 发送积分
const doTopicURL = "/gateway/hc-neighbour/dailyPerf/doTopic";
//删除创业达人
const deleteTopicURL = "/gateway/hc-neighbour/topic/deleteTopic";
export {
  getTopicListURL,
  getTopicDetailURL,
  addTopicURL,
  updateTopicURL,
  getCommunityListUrl,
  // getInitInfoURL,
  getCommentPraiseRankingListURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
  doTopicURL,
  deleteTopicURL,
};
